<template>
  <div class="popup-panel-form test q-py-sm q-px-md">
    <div class="row q-py-md">
      <p class="title">{{ $t("sidebar.customPanel") }}</p>
      <img
        src="@/assets/icons/close-white.svg"
        style="margin-left: auto; cursor: pointer"
        width="15"
        @click="handleCustomPanelFormPopup(false)"
      />
    </div>
    <div class="section-bg">
      <div class="q-mx-lg q-py-sm input-title">
        <p>{{ $t("sidebar.name") }}</p>
      </div>
      <div class="q-mx-sm">
        <q-input
          dense
          filled
          :error="submitted && 'name' in errors"
          :error-message="errors['name']"
          ref="nameRef"
          v-model="formData.name"
          class="form-input"
          :rules="[
            (val) => (!!val && val !== '') || $t('errors.nameMaxLength'),
            (val) => val.length <= 25 || $t('errors.nameMaxLength'),
          ]"
          input-style="color: white; text-align: left;"
        />
      </div>
    </div>
    <div class="section-bg q-pb-sm">
      <div class="q-mx-lg q-py-sm input-title">
        <p>{{ $t("sidebar.height") }}</p>
      </div>
      <div style="width: 85%; margin: auto" class="row">
        <div class="col-md-1 col-lg-1 q-mt-xs">
          <img src="@/assets/icons/height.svg" />
        </div>
        <div class="col-md-10 col-lg-10 responsive-height">
          <q-input
            style="width: 75%; display: inline-block"
            dense
            filled
            type="number"
            ref="heightRef"
            v-model="formData.size.height"
            class="form-input"
            :error="submitted && 'height' in errors"
            :error-message="errors['height']"
            :rules="[
              (val) => (!!val && val !== 0) || $t('errors.heightRange'),
              (val) => (val >= 200 && val <= 3000) || $t('errors.heightRange'),
            ]"
            hide-bottom-space="false"
            input-style="color: white; text-align: left;"
          />
          <span class="mm">mm</span>
        </div>
      </div>
    </div>
    <div class="section-bg q-pb-sm">
      <div class="q-mx-lg q-py-sm input-title">
        <p>{{ $t("sidebar.width") }}</p>
      </div>
      <div style="width: 85%; margin: auto" class="row">
        <div class="col-md-1 col-lg-1 q-mt-xs">
          <img src="@/assets/icons/width.svg" />
        </div>
        <div class="col-md-10 col-lg-10 responsive-height">
          <q-input
            style="width: 75%; display: inline-block"
            dense
            filled
            type="number"
            ref="widthRef"
            v-model="formData.size.width"
            class="form-input"
            :error="submitted && 'width' in errors"
            :error-message="errors['width']"
            :rules="[
              (val) => (!!val && val !== 0) || $t('errors.heightRange'),
              (val) => (val >= 200 && val <= 3000) || $t('errors.heightRange'),
            ]"
            hide-bottom-space="false"
            input-style="color: white; text-align: left;"
          />
          <span class="mm">mm</span>
        </div>
      </div>
    </div>
    <div class="section-bg q-pb-sm">
      <div class="q-mx-lg q-py-sm input-title">
        <p>{{ $t("sidebar.selectColour") }}</p>
      </div>
      <div style="width: 90%; margin: auto; height: 35px" class="row q-mx-sm">
        <div class="col-sm-12 col-md-3 col-lg-3">
          <img
            :class="formData.textureId === '60' && 'selected-color'"
            @click="handleSelectColor('60')"
            class="panel-img cursor-pointer"
            src="/assets/textures/texture-grey.svg"
          />
        </div>
        <div class="col-sm-12 col-md-3 col-lg-3">
          <img
            class="panel-img cursor-pointer"
            :class="formData.textureId === '80' && 'selected-color'"
            src="/assets/textures/texture-blue.svg"
            @click="handleSelectColor('80')"
          />
        </div>
        <div class="col-sm-12 col-md-3 col-lg-3">
          <img
            :class="formData.textureId === '90' && 'selected-color'"
            @click="handleSelectColor('90')"
            class="panel-img cursor-pointer"
            src="/assets/textures/texture-black.svg"
          />
        </div>
      </div>
    </div>
    <q-btn
      no-caps
      style="color: black; width: 100%"
      class="q-mt-sm"
      @click="createCustomPanel"
      :disable="!isFormValid"
      :style="!isFormValid ? 'background: #a0a0a0;' : 'background: #23e7a5;'"
    >
      {{ panelToEdit ? $t("sidebar.edit") : $t("sidebar.create") }}
    </q-btn>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { mapActions } from "vuex";
import API from "@/api/API.js";

export default {
  name: "CustomPanelForm",
  props: [
    "handleCustomPanelFormPopup",
    "activeUserId",
    "loadCustomPanelTextures",
  ],
  setup() {
    const nameRef = ref(null);
    const heightRef = ref(null);
    const widthRef = ref(null);

    return {
      nameRef,
      heightRef,
      widthRef,
    };
  },
  data() {
    return {
      formData: {
        userId: this.activeUserId,
        name: null,
        textureId: "60",
        size: {
          height: null,
          width: null,
        },
        show: true,
      },
      errors: {},
      submitted: false,
    };
  },
  computed: {
    isFormValid() {
      return (
        this.formData.name &&
        this.formData.size.height &&
        this.formData.size.width &&
        !this.errors.name &&
        !this.errors.height &&
        !this.errors.width
      );
    },
  },
  watch: {
    formData: {
      handler() {
        if (this.submitted) {
          this.validateFields();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["getCustomPanels", "setNewCustomPanels"]),
    handleSelectColor(selectedColor) {
      this.formData.textureId = selectedColor;
    },
    validateFields() {
      this.submitted = true;
      this.errors = {};

      if (!this.formData.name || this.formData.name.length > 25) {
        this.errors["name"] = this.$t("errors.nameMaxLength");
      }
      if (
        !this.formData.size.height ||
        this.formData.size.height < 200 ||
        this.formData.size.height > 3000
      ) {
        this.errors["height"] = this.$t("errors.heightRange");
      }
      if (
        !this.formData.size.width ||
        this.formData.size.width < 200 ||
        this.formData.size.width > 3000
      ) {
        this.errors["width"] = this.$t("errors.heightRange");
      }

      return Object.keys(this.errors).length === 0;
    },
    resetForm() {
      this.formData.name = null;
      this.formData = {};
      this.submitted = false;
    },
    async createCustomPanel() {
      const isValid = this.validateFields();
      if (!isValid) return;

      try {
        await API.airteam3DViewer.createCustomPanel(this.formData);
        await this.getCustomPanels();
        await this.loadCustomPanelTextures();
        this.resetForm();
        this.handleCustomPanelFormPopup(false);
      } catch (error) {
        if (error.response.data?.code === "duplicate_panel_name") {
          this.errors["name"] = this.$t("errors.duplicatePanelName");
        }
        console.error("Failed to create custom panel:", error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.popup-panel-form {
  text-align: left;
  background-color: #27292c;
  position: absolute;
  top: 60px;
  right: 270px;
  width: 280px;
  color: white;
  border-radius: 8px;
}

@media (min-width: 1400px) {
  .popup-panel-form {
    width: 310px;
  }
  .responsive-height {
    min-height: 68px;
  }
}
.responsive-height {
  min-height: 76px;
}

.section-bg {
  padding: 10px 0;
  background-color: #333537;
  border-radius: 6px;
  margin-bottom: 8px;
}

.form-input {
  color: white;
}

.form-input :deep(.q-field__control) {
  border-radius: 6px;
  background-color: #27292c;
  height: 33px;
  color: white;
}

.form-input :deep(.q-field__append) {
  width: 0;
  opacity: 0;
}

.form-input :deep(.q-field__bottom) {
  width: 150%;
  max-width: 250px;
  padding: 8px 0;
  color: #ff5050;
}

.input-title {
  font-size: 17px;
}

.panel-img {
  width: 35px;
  height: 22px;
  margin-right: 8px;
  z-index: 2;
}

.selected-color {
  width: 39px;
  height: 26px;
  border: 2px solid #23e7a5;
}

.error {
  color: #d92f39;
  font-size: 11px;
  text-align: left;
  width: 150%;
}

.preview-image {
  background-color: #27292c;
  position: absolute;
  top: 2px;
  right: 275px;
  width: 160px;
  border-radius: 8px;
  height: 300px;
}

.title {
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.mm {
  font-size: 13px;
  font-weight: 400;
}
</style>
