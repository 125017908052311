import * as THREE from "three";
import { SOLAR_POINT_COLOR, MIDPOINT_COLOR, POINT_COLOR } from "../constants";

export const undoAddPoint = function (
  area,
  point,
  line,
  midPoint,
  oldTempLine,
  oldFirstPoint,
  newTempLine,
  oldTempLabel,
  newTempLabel,
  fixedLine
) {
  // Add action to redo stack
  this.redoStack.push({
    action: "ADD_POINT",
    area: area,
    point: point,
    line: line,
    midPoint: midPoint,
    oldTempLine: oldTempLine,
    oldFirstPoint: oldFirstPoint,
    newTempLine: newTempLine,
    oldTempLabel: oldTempLabel,
    newTempLabel: newTempLabel,
    fixedLine: fixedLine,
  });
  // Find and remove the point from the areas
  const pointIndex = area.points.findIndex((p) => p.id === point.id);
  area.points.splice(pointIndex, 1);
  const lineIndex = area.lines.findIndex((l) => l.id === line.id);
  area.lines.splice(lineIndex, 1);

  // Hide the line, point, and midpoint objects
  this.hideObjectFromScene(point);
  this.hideObjectFromScene(line);
  this.hideObjectFromScene(midPoint);
  this.hideObjectFromScene(newTempLabel);
  this.removeDashedLine();

  area.firstPoint = oldFirstPoint;
  area.tempLine = oldTempLine;
  if (oldTempLabel) area.tempLabel = null;
  if (oldTempLabel) area.tempLabel = oldTempLabel;
  if (oldTempLabel) this.removeObjectFromScene(oldTempLabel);

  if (this.measurementAreaEndingLine) {
    this.removeObjectFromScene(this.measurementAreaEndingLine);
    this.measurementAreaEndingLine = null;
  }

  if (area.points.length === 0) {
    this.measurementAreas.splice(area, 1);
    this.resetRedoStack();
  }
};

export const redoAddPoint = function (
  area,
  point,
  line,
  midPoint,
  oldTempLine,
  oldFirstPoint,
  newTempLine,
  oldTempLabel,
  newTempLabel,
  fixedLine
) {
  // add action to undo stack
  this.undoStack.push({
    action: "ADD_POINT",
    area: area,
    point: point,
    line: line,
    midPoint: midPoint,
    oldTempLine: oldTempLine,
    oldFirstPoint: oldFirstPoint,
    newTempLine: newTempLine,
    oldTempLabel: oldTempLabel,
    newTempLabel: newTempLabel,
    fixedLine: fixedLine,
  });

  area.points.push(point);
  if (fixedLine) {
    this.updateLabelBetweenTwoPoints(
      fixedLine.label,
      fixedLine.firstPoint.position,
      fixedLine.secondPoint.position,
      0.5,
      true
    );
    area.lines.push(fixedLine);
  }

  if (line) line.visible = true;
  if (point) point.visible = true;
  if (midPoint) midPoint.visible = true;
  if (newTempLabel) newTempLabel.visible = true;

  this.removeDashedLine();

  area.firstPoint = point;
  area.tempLine = newTempLine;
  if (newTempLabel) area.tempLabel = newTempLabel;

  if (this.measurementAreaEndingLine) {
    this.removeObjectFromScene(this.measurementAreaEndingLine);
    this.measurementAreaEndingLine = null;
  }
};

export const undoMovePoint = function (areaType, area, index, position) {
  const newPosition = new THREE.Vector3();
  const transformationMatrix = new THREE.Matrix4();

  area.pointInstancedMesh.getMatrixAt(index, transformationMatrix);

  transformationMatrix.decompose(
    newPosition,
    new THREE.Quaternion(),
    new THREE.Vector3()
  );
  const originalPosition = newPosition.clone();

  newPosition.set(position.x, position.y, position.z);
  transformationMatrix.setPosition(newPosition);

  area.pointInstancedMesh.setMatrixAt(index, transformationMatrix);

  area.pointInstancedMesh.instanceMatrix.needsUpdate = true;
  area.pointInstancedMesh.computeBVH();

  area.points[index].position.set(position.x, position.y, position.z);

  this.draggedAreaPoint = {
    area,
    index,
  };

  this.reDrawMeasurementAreaFromPoint();

  this.draggedAreaPoint = null;

  this.redoStack.push({
    action: "MOVE_POINT",
    areaType: areaType,
    area,
    index,
    position: originalPosition,
  });
};

export const undoMovePointOld = function (areaType, area, point) {
  const object = this.scene.getObjectById(point.id);
  // add action to redo stack
  const lastPosition = {
    x: object.lastPosition.x,
    y: object.lastPosition.y,
    z: object.lastPosition.z,
  };
  const currentPosition = {
    x: object.position.x,
    y: object.position.y,
    z: object.position.z,
  };
  this.redoStack.push({
    action: "MOVE_POINT",
    areaType: areaType,
    area: area,
    point: point,
  });

  // execute undo action
  object.position.x = lastPosition.x;
  object.position.y = lastPosition.y;
  object.position.z = lastPosition.z;

  object.lastPosition.x = currentPosition.x;
  object.lastPosition.y = currentPosition.y;
  object.lastPosition.z = currentPosition.z;

  switch (areaType) {
    case "MOUNTING":
      const selectedArea = this.reDrawAreaFromPoint(object);

      this.updateAreaObject(
        selectedArea.id,
        selectedArea.points.map((point) => {
          return {
            x: point.position.x,
            y: point.position.y,
            z: point.position.z,
          };
        })
      );
      break;
    case "MEASUREMENT_AREA":
      const selectedMeasurementArea =
        this.reDrawMeasurementAreaFromPoint(object);

      this.updateMeasurementAreaObject(
        selectedMeasurementArea.id,
        selectedMeasurementArea.points.map((point) => {
          return {
            x: point.position.x,
            y: point.position.y,
            z: point.position.z,
          };
        })
      );
      break;
    default:
      null;
  }
};

export const undoMoveMidPoint = function (area, index, isMeasurement) {
  const oldPoints = [...area.points];

  area.points.splice(index, 1);

  const instancedMesh = this.replacePointsWithInstancedMesh(
    area.points,
    isMeasurement ? SOLAR_POINT_COLOR : POINT_COLOR,
    area.pointInstancedMesh
  );
  this.scene.add(instancedMesh);
  area.pointInstancedMesh = instancedMesh;

  for (let line of area.lines) {
    this.removeObjectFromScene(line.midPoint);
    if (isMeasurement) this.removeObjectFromScene(line.label);
  }

  this.reCreateLinesFromPoints(area);

  const midpointInstancedMesh = this.replacePointsWithInstancedMesh(
    area.lines.map((line) => line.midPoint),
    isMeasurement ? SOLAR_POINT_COLOR : POINT_COLOR,
    area.midpointInstancedMesh,
    true
  );
  this.scene.add(midpointInstancedMesh);
  area.midpointInstancedMesh = midpointInstancedMesh;

  this.draggedAreaPoint = {
    area,
    index: index - 1,
  };

  this.reDrawMeasurementAreaFromPoint();

  this.draggedAreaPoint = null;

  // Add to redo stack
  this.redoStack.push({
    action: "MOVE_MID_POINT",
    areaType: "MEASUREMENT_AREA",
    area,
    index: index,
    points: oldPoints,
    isMeasurement,
  });
};

export const undoMoveMeasurementPoint = function (measurement, point) {
  const object = this.scene.getObjectById(point.id);
  // add action to redo stack
  const lastPosition = {
    x: object.lastPosition.x,
    y: object.lastPosition.y,
    z: object.lastPosition.z,
  };
  const currentPosition = {
    x: object.position.x,
    y: object.position.y,
    z: object.position.z,
  };
  this.redoStack.push({
    action: "MOVE_MEASUREMENT_POINT",
    measurement: measurement,
    point: point,
  });

  // execute undo action
  object.position.x = lastPosition.x;
  object.position.y = lastPosition.y;
  object.position.z = lastPosition.z;

  object.lastPosition.x = currentPosition.x;
  object.lastPosition.y = currentPosition.y;
  object.lastPosition.z = currentPosition.z;

  this.reDraw(point);

  this.updateMeasurementObject(measurement.id, [
    {
      x: measurement.firstPoint.position.x,
      y: measurement.firstPoint.position.y,
      z: measurement.firstPoint.position.z,
    },
    {
      x: measurement.secondPoint.position.x,
      y: measurement.secondPoint.position.y,
      z: measurement.secondPoint.position.z,
    },
  ]);
};

export const redoMoveMidPoint = function (area, index, points, isMeasurement) {
  area.points = points;

  const instancedMesh = this.replacePointsWithInstancedMesh(
    area.points,
    isMeasurement ? SOLAR_POINT_COLOR : POINT_COLOR,
    area.pointInstancedMesh
  );
  this.scene.add(instancedMesh);
  area.pointInstancedMesh = instancedMesh;

  for (let line of area.lines) {
    this.removeObjectFromScene(line.midPoint);
    if (isMeasurement) this.removeObjectFromScene(line.label);
  }

  this.reCreateLinesFromPoints(area);

  const midpointInstancedMesh = this.replacePointsWithInstancedMesh(
    area.lines.map((line) => line.midPoint),
    isMeasurement ? SOLAR_POINT_COLOR : POINT_COLOR,
    area.midpointInstancedMesh,
    true
  );
  this.scene.add(midpointInstancedMesh);
  area.midpointInstancedMesh = midpointInstancedMesh;

  this.draggedAreaPoint = {
    area,
    index: index,
  };

  this.reDrawMeasurementAreaFromPoint();

  this.draggedAreaPoint = null;

  this.undoStack.push({
    action: "MOVE_MID_POINT",
    areaType: "MEASUREMENT_AREA",
    area,
    index: index,
    isMeasurement,
  });
};

export const redoMovePoint = function (areaType, area, index, position) {
  const newPosition = new THREE.Vector3();
  const transformationMatrix = new THREE.Matrix4();

  area.pointInstancedMesh.getMatrixAt(index, transformationMatrix);

  transformationMatrix.decompose(
    newPosition,
    new THREE.Quaternion(),
    new THREE.Vector3()
  );
  const originalPosition = newPosition.clone();

  newPosition.set(position.x, position.y, position.z);
  transformationMatrix.setPosition(newPosition);

  area.pointInstancedMesh.setMatrixAt(index, transformationMatrix);

  area.pointInstancedMesh.instanceMatrix.needsUpdate = true;
  area.pointInstancedMesh.computeBVH();

  area.points[index].position.set(position.x, position.y, position.z);

  this.draggedAreaPoint = {
    area,
    index,
  };

  this.reDrawMeasurementAreaFromPoint();

  this.draggedAreaPoint = null;

  this.undoStack.push({
    action: "MOVE_POINT",
    areaType: areaType,
    area,
    index,
    position: originalPosition,
  });
};

export const redoMovePointOld = function (areaType, area, point) {
  const object = this.scene.getObjectById(point.id);

  // add action to undo stack
  const lastPosition = {
    x: object.lastPosition.x,
    y: object.lastPosition.y,
    z: object.lastPosition.z,
  };
  const currentPosition = {
    x: object.position.x,
    y: object.position.y,
    z: object.position.z,
  };
  this.undoStack.push({
    action: "MOVE_POINT",
    areaType: areaType,
    area: area,
    point: point,
  });

  // execute redo action
  object.position.x = lastPosition.x;
  object.position.y = lastPosition.y;
  object.position.z = lastPosition.z;

  object.lastPosition.x = currentPosition.x;
  object.lastPosition.y = currentPosition.y;
  object.lastPosition.z = currentPosition.z;

  switch (areaType) {
    case "MOUNTING":
      const selectedArea = this.reDrawAreaFromPoint(object);

      this.updateAreaObject(
        selectedArea.id,
        selectedArea.points.map((point) => {
          return {
            x: point.position.x,
            y: point.position.y,
            z: point.position.z,
          };
        })
      );
      break;
    case "MEASUREMENT_AREA":
      const selectedMeasurementArea =
        this.reDrawMeasurementAreaFromPoint(object);

      this.updateMeasurementAreaObject(
        selectedMeasurementArea.id,
        selectedMeasurementArea.points.map((point) => {
          return {
            x: point.position.x,
            y: point.position.y,
            z: point.position.z,
          };
        })
      );
      break;
    default:
      null;
  }
};

export const redoMoveMeasurementPoint = function (measurement, point) {
  const object = this.scene.getObjectById(point.id);
  // add action to redo stack
  const lastPosition = {
    x: object.lastPosition.x,
    y: object.lastPosition.y,
    z: object.lastPosition.z,
  };
  const currentPosition = {
    x: object.position.x,
    y: object.position.y,
    z: object.position.z,
  };
  this.undoStack.push({
    action: "MOVE_MEASUREMENT_POINT",
    measurement: measurement,
    point: point,
  });

  // execute undo action
  object.position.x = lastPosition.x;
  object.position.y = lastPosition.y;
  object.position.z = lastPosition.z;

  object.lastPosition.x = currentPosition.x;
  object.lastPosition.y = currentPosition.y;
  object.lastPosition.z = currentPosition.z;

  this.reDraw(point);

  this.updateMeasurementObject(measurement.id, [
    {
      x: measurement.firstPoint.position.x,
      y: measurement.firstPoint.position.y,
      z: measurement.firstPoint.position.z,
    },
    {
      x: measurement.secondPoint.position.x,
      y: measurement.secondPoint.position.y,
      z: measurement.secondPoint.position.z,
    },
  ]);
};

export const undoDeleteArea = async function (area) {
  // add action to redo stack
  this.redoStack.push({ action: "DELETE_AREA", area: area });

  // execute undo action
  const areaObject = this.scene.getObjectById(area.plane.id);
  for (let panel of area.panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    panelObject.visible = true;
  }
  for (let point of area.points) {
    const pointObject = this.scene.getObjectById(point.id);
    pointObject.visible = true;
  }
  for (let line of area.lines) {
    const lineObject = this.scene.getObjectById(line.line.id);
    const midPointObject = this.scene.getObjectById(line.midPoint.id);
    lineObject.visible = true;
    midPointObject.visible = true;
  }
  this.areas.push(area);
  areaObject.visible = true;
  if (!this.anonymousUser) {
    try {
      const { data } = await this.createAreaObject(
        area.points.map((point) => {
          return {
            x: point.position.x,
            y: point.position.y,
            z: point.position.z,
          };
        })
      );
      area.id = data;
    } catch (e) {}
  }
};

export const undoDeleteMeasurementArea = async function (area) {
  this.displayAreaPoint(area, true);

  const measurementArea =
    this.measurementAreas[this.measurementAreas.length - 1];

  this.drawMeasurementAreaPlane(measurementArea?.points, false);

  this.unselectMeasurementArea(measurementArea.plane);

  this.redoStack.push({
    action: "DELETE_MEASUREMENT_AREA",
    area: measurementArea,
  });

  if (!this.anonymousUser) {
    try {
      const { data } = await this.createMeasurementAreaObject(
        measurementArea.points.map((point) => {
          return {
            x: point.position.x,
            y: point.position.y,
            z: point.position.z,
          };
        })
      );
      measurementArea.id = data;
    } catch (e) {}
  }
};

export const undoDeleteMeasurement = async function (measurement) {
  this.displayMarker(measurement, measurement.position[0], true);
  this.displayMarker(measurement, measurement.position[1], true);

  const measurementObject = this.measurements[this.measurements.length - 1];

  if (!this.anonymousUser) {
    try {
      const firstPoint = new THREE.Vector3();
      const secondPoint = new THREE.Vector3();

      measurementObject.firstPoint.getWorldPosition(firstPoint);
      measurementObject.secondPoint.getWorldPosition(secondPoint);

      let points = [firstPoint, secondPoint];
      const { data } = await this.createMeasurementObject(points);
      measurementObject.id = data;
    } catch (e) {}
  }

  this.redoStack.push({
    action: "DELETE_MEASUREMENT",
    measurement: measurementObject,
  });
};

export const redoDeleteArea = function (area) {
  // add action to undo stack
  this.undoStack.push({ action: "DELETE_AREA", area: area });

  const panelPromises = [];

  // execute redo action
  const areaObject = this.scene.getObjectById(area.plane.id);
  for (let panel of area.panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    panelPromises.push(this.deletePanelObject(panel.id));

    panelObject.visible = false;
  }
  for (let point of area.points) {
    this.hideObjectFromScene(point);
  }
  for (let line of area.lines) {
    this.hideObjectFromScene(line.line);
    this.hideObjectFromScene(line.midPoint);
  }
  this.areas = this.areas.filter((area) => area.plane.id !== area.plane.id);
  areaObject.visible = false;

  Promise.all(panelPromises);
  this.deleteAreaObject(area.id);
};

export const redoDeleteMeasurementArea = function (area) {
  const areaPositions = area.points.map((point) => {
    return {
      x: point.position.x,
      y: point.position.y,
      z: point.position.z,
    };
  });

  this.removeObjectFromScene(area.plane);
  this.removeObjectFromScene(area.label);

  for (let point of area.points) {
    this.removeObjectFromScene(point);
  }
  for (let line of area.lines) {
    this.removeObjectFromScene(line.midPoint);
    this.removeObjectFromScene(line.label);
  }

  this.removeObjectFromScene(area.combinedLine);

  this.measurementAreas = this.measurementAreas.filter(
    (area) => area.plane.id !== area.plane.id
  );

  if (!this.anonymousUser) {
    this.deleteMeasurementAreaObject(area.id);
  }

  this.undoStack.push({
    action: "DELETE_MEASUREMENT_AREA",
    area: { position: areaPositions },
  });
};

export const redoDeleteMeasurement = async function (measurement) {
  this.undoStack.push({
    action: "DELETE_MEASUREMENT",
    measurement: {
      position: [
        {
          x: measurement.firstPoint.position.x,
          y: measurement.firstPoint.position.y,
          z: measurement.firstPoint.position.z,
        },
        {
          x: measurement.secondPoint.position.x,
          y: measurement.secondPoint.position.y,
          z: measurement.secondPoint.position.z,
        },
      ],
      show: true,
    },
  });

  this.removeObjectFromScene(measurement.line);
  this.removeObjectFromScene(measurement.label);
  this.removeObjectFromScene(measurement.firstPoint);
  this.removeObjectFromScene(measurement.secondPoint);
  this.removeObjectFromScene(measurement.xAxisLine);
  this.removeObjectFromScene(measurement.xAxisLabel);
  this.removeObjectFromScene(measurement.yAxisLine);
  this.removeObjectFromScene(measurement.yAxisLabel);

  this.measurements = this.measurements.filter(
    (m) => m.line.id !== measurement.line.id
  );

  if (!this.anonymousUser) {
    this.deleteMeasurementObject(measurement.id);
  }
};

export const undoMergePoint = function (area, points, isMeasurement) {
  const oldPoints = [...area.points];

  area.points = points;

  const instancedMesh = this.replacePointsWithInstancedMesh(
    points,
    isMeasurement ? SOLAR_POINT_COLOR : POINT_COLOR,
    area.pointInstancedMesh
  );
  this.scene.add(instancedMesh);
  area.pointInstancedMesh = instancedMesh;

  for (let line of area.lines) {
    this.removeObjectFromScene(line.midPoint);
    if (isMeasurement) this.removeObjectFromScene(line.label);
  }

  this.reCreateLinesFromPoints(area);

  const midpointInstancedMesh = this.replacePointsWithInstancedMesh(
    area.lines.map((line) => line.midPoint),
    isMeasurement ? SOLAR_POINT_COLOR : POINT_COLOR,
    area.midpointInstancedMesh,
    true
  );
  this.scene.add(midpointInstancedMesh);
  area.midpointInstancedMesh = midpointInstancedMesh;

  this.draggedAreaPoint = {
    area,
    index: null,
  };

  this.reDrawMeasurementAreaFromPoint(true);

  this.draggedAreaPoint = null;

  this.redoStack.push({
    action: "MERGE_POINT",
    area,
    points: oldPoints,
    isMeasurement,
  });
};

export const redoMergePoint = function (area, points, isMeasurement) {
  const oldPoints = [...area.points];

  area.points = points;

  const instancedMesh = this.replacePointsWithInstancedMesh(
    points,
    isMeasurement ? SOLAR_POINT_COLOR : POINT_COLOR,
    area.pointInstancedMesh
  );
  this.scene.add(instancedMesh);
  area.pointInstancedMesh = instancedMesh;

  for (let line of area.lines) {
    this.removeObjectFromScene(line.midPoint);
    if (isMeasurement) this.removeObjectFromScene(line.label);
  }

  this.reCreateLinesFromPoints(area);

  const midpointInstancedMesh = this.replacePointsWithInstancedMesh(
    area.lines.map((line) => line.midPoint),
    isMeasurement ? SOLAR_POINT_COLOR : POINT_COLOR,
    area.midpointInstancedMesh,
    true
  );
  this.scene.add(midpointInstancedMesh);
  area.midpointInstancedMesh = midpointInstancedMesh;

  this.draggedAreaPoint = {
    area,
    index: null,
  };

  this.reDrawMeasurementAreaFromPoint(true);

  this.draggedAreaPoint = null;

  this.undoStack.push({
    action: "MERGE_POINT",
    area,
    points: oldPoints,
    isMeasurement,
  });
};
