<template>
  <div
    :class="customPanelFormPopupOpen ? 'hide' : ''"
    class="popup-panel-selection q-py-md q-px-md"
  >
    <div class="row">
      <h5>{{ $t("panelMenu.panelSelection") }}</h5>
      <img
        src="@/assets/icons/close-white.svg"
        style="margin-left: auto; cursor: pointer"
        width="15"
        @click="handlePanelSelectionPopup(false)"
      />
    </div>

    <div class="q-mt-lg" v-if="computedLikedPanel && !anonymousUser">
      <div class="q-mb-sm row">
        <div class="panel-title">
          <p>{{ $t("panelMenu.likedPanel") }}</p>
        </div>
      </div>
      <div>
        <div class="q-my-sm">
          <SinglePanel
            :editable="isLikedPanelCustom"
            :panel="computedLikedPanel"
            :anonymousUser="anonymousUser"
            :likedPanel="computedLikedPanel"
            :selected="computedLikedPanel?.id === selectedPanelId"
            @starClicked="handleStarClicked"
            @panelUnliked="unlikePanel"
            @panelSelected="
              (payload) => $emit('panelSelected', computedLikedPanel)
            "
            @deletePanel="handleRemovePanel"
          />
        </div>
      </div>
    </div>
    <div class="q-mt-lg" v-if="!anonymousUser">
      <div class="q-mb-sm row">
        <div class="panel-title">
          <p>{{ $t("sidebar.custom") }}</p>
        </div>
        <div style="margin-left: auto">
          <q-btn
            no-caps
            style="background-color: #23e7a5; color: black"
            class="row q-mt-sm"
            @click="handleCustomPanelFormPopup(!customPanelFormPopupOpen)"
          >
            <img
              src="@/assets/icons/plus.svg"
              width="15"
              style="margin-right: 5px"
            />
            {{ $t("sidebar.customPanel") }}
            <q-tooltip>
              {{ $t("sidebar.customPanel") }}
            </q-tooltip>
          </q-btn>
        </div>
      </div>
      <div v-if="availableCustomPanels.length > 0">
        <div
          class="q-my-sm"
          v-for="panel in availableCustomPanels"
          :key="panel.id"
        >
          <SinglePanel
            :editable="true"
            :anonymousUser="anonymousUser"
            :panel="panel"
            :selected="panel.id === selectedPanelId"
            @panelSelected="(payload) => $emit('panelSelected', payload)"
            @starClicked="handleStarClicked"
            @deletePanel="handleRemovePanel"
          />
        </div>
      </div>
    </div>
    <div class="q-mt-lg">
      <div class="panel-title">
        <p class="q-mb-sm">{{ $t("sidebar.predefined") }}</p>
      </div>
      <div v-if="predefinedPanels.length > 0">
        <div class="q-my-sm" v-for="panel in predefinedPanels" :key="panel.id">
          <SinglePanel
            :anonymousUser="anonymousUser"
            :panel="panel"
            :selected="panel.id === selectedPanelId"
            @panelSelected="(payload) => $emit('panelSelected', payload)"
            @starClicked="handleStarClicked"
          />
        </div>
      </div>
    </div>
  </div>
  <CustomPanelForm
    v-if="customPanelFormPopupOpen"
    :handleCustomPanelFormPopup="handleCustomPanelFormPopup"
    :activeUserId="activeUserId"
    :loadCustomPanelTextures="loadCustomPanelTextures"
  />
  <RemovePanelModal
    v-if="removePanelModalOpen"
    :showModal="removePanelModalOpen"
    @close-modal="closeRemovePanelModal"
    @confirm-deletion="deletePanel"
  />
</template>

<script>
import * as panelMethods from "@/modules/panel";
import { mapActions, mapGetters } from "vuex";
import SinglePanel from "@/components/RightSidebar/SinglePanel.vue";
import CustomPanelForm from "@/components/RightSidebar/CustomPanelForm.vue";
import RemovePanelModal from "@/components/RightSidebar/RemovePanelModal.vue";
import { VERTICAL } from "@/constants";
import API from "@/api/API.js";

export default {
  name: "PanelSelection",
  components: { CustomPanelForm, SinglePanel, RemovePanelModal },
  props: [
    "anonymousUser",
    "panelOrientation",
    "verticalPanels",
    "horizontalPanels",
    "handlePanelSelectionPopup",
    "activeUserId",
    "likedPanelProp",
    "starClicked",
    "unlikePanel",
    "likedPanelId",
    "selectedPanelId",
    "loadCustomPanelTextures",
    "getUserDefaultSolarValues",
  ],
  emits: ["panelSelected"],
  data() {
    return {
      likedPanel: null,
      texturePath: "/assets/textures/",
      customPanelFormPopupOpen: false,
      removePanelModalOpen: false,
      panelToRemove: null,
    };
  },
  computed: {
    ...mapGetters(["customPanels"]),
    predefinedPanels() {
      return this.panelOrientation
        ? this.verticalPanels.filter(
            (panel) => panel.id !== this.computedLikedPanel?.id
          )
        : this.horizontalPanels.filter(
            (panel) => panel.id !== this.computedLikedPanel?.id
          );
    },
    availableCustomPanels() {
      return this.customPanels
        .filter(
          (panel) =>
            !panel.is_deleted &&
            panel.orientation === VERTICAL &&
            panel.id !== this.computedLikedPanel?.id &&
            (panel.companyId || panel.userId === this.activeUserId)
        )
        .sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));
    },
    computedLikedPanel() {
      if (this.likedPanelProp) {
        return this.likedPanelProp;
      } else if (this.likedPanelId) {
        const customPanels = this.customPanels.filter(
          (panel) => panel.show && panel.orientation === VERTICAL
        );
        const allPanels = [].concat(this.predefinedPanels, customPanels);
        return allPanels.find((panel) => panel.id === this.likedPanelId);
      }
      return null;
    },
    isLikedPanelCustom() {
      return this.customPanels.some(
        (panel) => panel.id === this.computedLikedPanel?.id
      );
    },
  },
  methods: {
    ...Object.fromEntries(
      [...Object.entries(panelMethods)].map(([key, value]) => [
        key,
        function (...args) {
          return value.apply(this, args);
        },
      ])
    ),
    ...mapActions(["getCustomPanels"]),
    handleStarClicked(panel) {
      this.starClicked(panel);
    },
    handleCustomPanelFormPopup(open) {
      this.customPanelFormPopupOpen = open;
    },
    handleRemovePanel(panelId) {
      this.panelToRemove = panelId;
      this.removePanelModalOpen = true;
    },
    closeRemovePanelModal() {
      this.panelToRemove = null;
      this.removePanelModalOpen = false;
    },
    async deletePanel() {
      this.customPanels.find(
        (panel) => panel.id === this.panelToRemove
      ).show = false;
      await API.airteam3DViewer.updateCustomPanel({
        id: this.panelToRemove,
        is_deleted: true,
      });
      if (this.computedLikedPanel?.id === this.panelToRemove) {
        this.unlikePanel();
      }
      await this.getCustomPanels();
      await this.loadCustomPanelTextures();
      await this.getUserDefaultSolarValues();
      this.closeRemovePanelModal();
    },
  },
};
</script>

<style>
.popup-panel-selection {
  text-align: left;
  background-color: #27292c;
  position: absolute;
  top: 60px;
  right: 270px;
  width: 480px;
  color: white;
  height: 85vh;
  overflow-y: scroll;
  border-radius: 8px;
}

.panel-title {
  color: white;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.hide {
  display: none;
}
</style>
