import axios from "axios";
import Global from "./Global";
import store from "@/store";
import getCookie from "@/utils/get-cookie";
import { isProduction } from "../../src/utils/env";
import { ElMessage } from "element-plus";

axios.defaults.headers.common["X-CSRFToken"] = getCookie(
  `${isProduction ? "" : "staging"}csrftoken`
);
axios.defaults.withCredentials = true;
axios.defaults.baseURL = window.runtimeConfig?.AFP_SERVER_URL;

let messageInstance = null;
let syncingInProgress = false;

const Request = {
  post: (url, data, contentType = "application/json") => {
    const method = "POST";
    return RequestHandler.handleRequest(url, data, method, contentType);
  },
  postWithAccessToken: (url, data, contentType = "application/json") => {
    const method = "POST";
    return SolarRequestHandler.handleRequest(
      url,
      data,
      method,
      contentType,
      Global.accept,
      store.getters.token
    );
  },
  get: (
    url,
    parameters,
    contentType = "application/json",
    accept = "application/json"
  ) => {
    const method = "GET";
    return RequestHandler.handleRequest(
      url,
      parameters,
      method,
      contentType,
      accept
    );
  },
  getWithAccessToken: (
    url,
    parameters,
    contentType = "application/json",
    accept = "application/json"
  ) => {
    const method = "GET";
    return SolarRequestHandler.handleRequest(
      url,
      parameters,
      method,
      contentType,
      accept,
      store.getters.token
    );
  },
  get_file: (url, data) => {
    const method = "POST";
    return RequestHandler.handleFileRequest(
      url,
      data,
      method
    );
  },
  patch: (url, data, contentType = "application/json") => {
    const method = "PATCH";
    return RequestHandler.handleRequest(url, data, method, contentType);
  },
  patchWithAccessToken: (url, data, contentType = "application/json") => {
    const method = "PATCH";
    return SolarRequestHandler.handleRequest(
      url,
      data,
      method,
      contentType,
      Global.accept,
      store.getters.token
    );
  },
  put: (url, data, contentType = "application/json") => {
    const method = "PUT";
    return RequestHandler.handleRequest(url, data, method, contentType);
  },
  putWithAccessToken: (url, data, contentType = "application/json") => {
    const method = "PUT";
    return SolarRequestHandler.handleRequest(
      url,
      data,
      method,
      contentType,
      Global.accept,
      store.getters.token
    );
  },
  option: (url, data, contentType = "application/json") => {
    const method = "OPTIONS";
    return RequestHandler.handleRequest(url, data, method, contentType);
  },
  delete: (url, parameters, contentType = "application/json") => {
    const method = "DELETE";
    return RequestHandler.handleRequest(url, parameters, method, contentType);
  },
  deleteWithAccessToken: (url, data, contentType = "application/json") => {
    const method = "DELETE";
    return SolarRequestHandler.handleRequest(
      url,
      data,
      method,
      contentType,
      Global.accept,
      store.getters.token
    );
  },
};

const RequestHandler = {
  handleRequest: (url, data, method, contentType, accept) => {
    return new Promise((resolve, reject) => {
      axios({
        method,
        url,
        data,
        headers: {
          Accept: accept || Global.accept,
          "Content-Type": contentType,
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  handleFileRequest: (url, data, method) => {
    return new Promise((resolve, reject) => {
      axios({
        method,
        url,
        data,
        responseType: "blob",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  handleResponse: (response) => {
    return response;
  },
};

const SolarRequestHandler = {
  handleRequest: async (url, data, method, contentType, accept, token) => {
    if (checkTokenExpiration()) return;
    const retryPromise = () => {
      return new Promise((resolve, reject) => {
        axios({
          method,
          url,
          data,
          headers: {
            Accept: accept || Global.accept,
            "Content-Type": contentType,
            "x-access-token": token,
          },
        })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    };
    try {
      return await retryRequest(retryPromise);
    } catch (error) {
      throw error;
    }
  },
  handleResponse: (response) => {
    return response;
  },
};

const isTokenExpired = function () {
  const { isSample, isAnonymous, decodedToken } = store.getters;
  if (isSample || isAnonymous) {
    return false;
  }
  if (!decodedToken || !decodedToken?.exp) {
    return true;
  }
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime >= decodedToken.exp;
};

const checkTokenExpiration = function () {
  const tokenExpired = isTokenExpired();
  if (tokenExpired) {
    const { projectId } = store.getters;
    if (messageInstance) {
      messageInstance.close();
    }
    messageInstance = ElMessage.error({
      message:
        "Token expired. Please go to project page to generate a new token.",
      showClose: true,
    });
    setTimeout(() => {
      window.location.href = `${window.runtimeConfig?.AFP_URL}project/${projectId}`;
    }, 2500);
  }
  return tokenExpired;
};

const checkInternetConnection = function () {
  if (!navigator.onLine) {
    return false;
  }
  return true;
};

const retryRequest = async function (promise) {
  try {
    const response = await promise();
    if (syncingInProgress) {
      messageInstance.close();
      messageInstance = ElMessage.success({
        message: "Syncing completed successfully.",
      });
      syncingInProgress = false;
    }
    return response;
  } catch (error) {
    if (
      !checkInternetConnection() ||
      (error.response && error.response.status >= 500)
    ) {
      if (!syncingInProgress) {
        messageInstance = ElMessage.warning({
          message: "Syncing in progress. Please don't close this window.",
          duration: 0,
        });
        syncingInProgress = true;
      }
      await new Promise((resolve) => setTimeout(resolve, 3000));
      return await retryRequest(promise);
    }
    throw error;
  }
};

export default Request;
